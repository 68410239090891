export const mockRelatorios = [
  {
    codigoArquivo: '11111111111111111111111111111111111111',
    nomeArquivo: 'LIBRA ENERGIA - ARQUIVO 1 - 202101 (LENERG).pdf',
    categoria: 'RELATORIOS',
    mimeType: 'application/pdf',
    cnpjUnidades: ['11111111111111'],
    aliasUnidade: 'LENERG',
    mesRef: '2021-01-01',
    nomeUnidade: 'UNIDADE 1',
    dataPublicacao: '2021-11-30'
  },
  {
    codigoArquivo: '222222222222222222222222222222222222',
    nomeArquivo: 'LIBRA ENERGIA - ARQUIVO 2 - 202102 (LENERG).pdf',
    categoria: 'RELATORIOS',
    mimeType: 'application/pdf',
    cnpjUnidades: ['11111111111111'],
    aliasUnidade: 'LENERG',
    mesRef: '2021-02-01',
    nomeUnidade: 'UNIDADE 2',
    dataPublicacao: '2021-11-30'
  },
  {
    codigoArquivo: '3333333333333333333333333333333333333333333333333333',
    nomeArquivo: 'LIBRA ENERGIA - ARQUIVO 3 - 202103 (LENERG).pdf',
    categoria: 'RELATORIOS',
    mimeType: 'application/pdf',
    cnpjUnidades: ['11111111111111'],
    aliasUnidade: 'LENERG',
    mesRef: '2021-03-01',
    nomeUnidade: 'UNIDADE 3',
    dataPublicacao: '2021-11-01'
  },
  {
    codigoArquivo: '4444444444444444444444444444444444444444444444444',
    nomeArquivo: 'LIBRA ENERGIA - ARQUIVO 4 - 202104 (LENERG).pdf',
    categoria: 'RELATORIOS',
    mimeType: 'application/pdf',
    cnpjUnidades: ['11111111111111'],
    aliasUnidade: 'LENERG',
    mesRef: '2021-04-01',
    nomeUnidade: 'UNIDADE 4',
    dataPublicacao: '2021-11-01'
  },
  {
    codigoArquivo: 'jassfsahdkfhasdjfhlasjdhdjflahj',
    nomeArquivo: 'LIBRA ENERGIA - ARQUIVO 5 - 202105 (LENERG).pdf',
    categoria: 'RELATORIOS',
    mimeType: 'application/pdf',
    cnpjUnidades: ['11111111111111'],
    aliasUnidade: 'LENERG',
    mesRef: '2021-05-01',
    nomeUnidade: 'TESTE',
    dataPublicacao: '2021-11-30'
  },
  {
    codigoArquivo: 'ashdfpahdfpadjfhaidfpasjkdfpkajsdkjfoaij',
    nomeArquivo: 'LIBRA ENERGIA - RELATÓRIO GERENCIAL - 202106 (LENERG).pdf',
    categoria: 'RELATORIOS',
    mimeType: 'application/pdf',
    cnpjUnidades: ['11111111111111'],
    aliasUnidade: 'LENERG',
    mesRef: '2021-06-01',
    nomeUnidade: 'TESTE',
    dataPublicacao: '2021-11-30'
  },
  {
    codigoArquivo: 'afjhsdjkflaknjflkandlkjfanskldjfalksjdfnlanlnjfd',
    nomeArquivo: 'LIBRA ENERGIA - RELATÓRIO GERENCIAL - 202107 (LENERG).pdf',
    categoria: 'RELATORIOS',
    mimeType: 'application/pdf',
    cnpjUnidades: ['11111111111111'],
    aliasUnidade: 'LENERG',
    mesRef: '2021-07-01',
    nomeUnidade: 'TESTE',
    dataPublicacao: '2021-11-30'
  },
  {
    codigoArquivo: 'dpfpdfosakdfpsgidkfnpsdfognpsdfgnpsngpnpsogifn',
    nomeArquivo: 'LIBRA ENERGIA - RELATÓRIO GERENCIAL - 202108 (LENERG).pdf',
    categoria: 'RELATORIOS',
    mimeType: 'application/pdf',
    cnpjUnidades: ['11111111111111'],
    aliasUnidade: 'LENERG',
    mesRef: '2021-08-01',
    nomeUnidade: 'TESTE',
    dataPublicacao: '2021-11-30'
  },
  {
    codigoArquivo: '08f9a0s8djfa0s98dfjasdf0908sdjf8a099j',
    nomeArquivo: 'LIBRA ENERGIA - RELATÓRIO GERENCIAL - 202109 (LENERG).pdf',
    categoria: 'RELATORIOS',
    mimeType: 'application/pdf',
    cnpjUnidades: ['11111111111111'],
    aliasUnidade: 'LENERG',
    mesRef: '2021-09-01',
    nomeUnidade: 'TESTE',
    dataPublicacao: '2021-11-30'
  },
  {
    codigoArquivo: 'adfjkalsdjfnlaskjdfnlasdnjfasdfjnlaskdjfnakljsdfnlans',
    nomeArquivo: 'LIBRA ENERGIA - RELATÓRIO GERENCIAL - 202110 (LENERG).pdf',
    categoria: 'RELATORIOS',
    mimeType: 'application/pdf',
    cnpjUnidades: ['11111111111111'],
    aliasUnidade: 'LENERG',
    mesRef: '2021-10-01',
    nomeUnidade: 'TESTE',
    dataPublicacao: '2021-11-30'
  }
];

export const mockContratoFornecedor = [
  {
    codigoArquivo: '4509w38t90he890hjsd0f8gth8s9r048h',
    nomeArquivo: 'LIPSUM - COMPRA CP - jan21 a jan21 (LENERG).pdf',
    categoria: 'CONTRATO_FORNECEDOR',
    mimeType: 'application/pdf',
    cnpjUnidades: ['11111111111111'],
    aliasUnidade: 'LENERG',
    fornecedor: 'LIPSUM',
    operacao: 'COMPRA',
    prazo: 'CP',
    vigenciaIni: '2021-01-01',
    vigenciaFim: '2021-01-01',
    nomeUnidade: 'TESTE',
    dataPublicacao: '2021-11-30'
  },
  {
    codigoArquivo: 'sgdgmfisidfgpomsdofgmsmidfogmpdfg08m',
    nomeArquivo: 'LIPSUM - COMPRA LP - jan20 a dez22 (LENERG).pdf',
    categoria: 'CONTRATO_FORNECEDOR',
    mimeType: 'application/pdf',
    cnpjUnidades: ['11111111111111'],
    aliasUnidade: 'LENERG',
    fornecedor: 'LIPSUM',
    operacao: 'COMPRA',
    prazo: 'LP',
    vigenciaIni: '2020-01-01',
    vigenciaFim: '2022-12-01',
    nomeUnidade: 'TESTE',
    dataPublicacao: '2021-11-30'
  },
  {
    codigoArquivo: 'sadfg4sdfh45df5j4gh6j54gf6hj4g54j6gfhj4',
    nomeArquivo: 'LIPSUM - COMPRA LP - jul19 a dez25 (LENERG).pdf',
    categoria: 'CONTRATO_FORNECEDOR',
    mimeType: 'application/pdf',
    cnpjUnidades: ['11111111111111'],
    aliasUnidade: 'LENERG',
    fornecedor: 'LIPSUM',
    operacao: 'COMPRA',
    prazo: 'LP',
    vigenciaIni: '2019-07-01',
    vigenciaFim: '2025-12-01',
    nomeUnidade: 'TESTE',
    dataPublicacao: '2021-11-30'
  },
  {
    codigoArquivo: 'v5cj4hi1f56vbmv5hu4iv4ui6h6=',
    nomeArquivo: 'LIPSUM - VENDA CP - jul21 a jul21 (LENERG).pdf',
    categoria: 'CONTRATO_FORNECEDOR',
    mimeType: 'application/pdf',
    cnpjUnidades: ['11111111111111'],
    aliasUnidade: 'LENERG',
    fornecedor: 'LIPSUM',
    operacao: 'CESSAO',
    prazo: 'CP',
    vigenciaIni: '2021-07-01',
    vigenciaFim: '2021-07-01',
    nomeUnidade: 'TESTE',
    dataPublicacao: '2021-11-30'
  }
];
