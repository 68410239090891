import dayjs from 'dayjs';
import Config from '@/config/config';
import { Base64 } from 'js-base64';

import Vue from 'vue';
import 'vue-toast-notification/dist/theme-sugar.css';
import VueToast from 'vue-toast-notification';

import { getAuth } from '../../features/auth/auth.helpers';

Vue.use(VueToast);

import 'dayjs/locale/pt-br';
import { mockContratoFornecedor, mockRelatorios } from '../../mock/arquivoMock';
dayjs.locale('pt-br');

const CONFIG = new Config();

export const fetchFiles = async (minDate, archiveType) => {
  if (archiveType === 'RELATORIOS') {
    return mockRelatorios;
  }
  return mockContratoFornecedor;
  // var axios = require('axios');
  // var data = { dataCorte: minDate, tipoArquivo: archiveType };

  // const { token } = getAuth();

  // var config = {
  //   method: 'post',
  //   url: `${CONFIG.API_URL}/arquivos/listByUser`,
  //   headers: {
  //     Bearer: token
  //   },
  //   data: data
  // };

  // return axios(config)
  //   .then(function (response) {
  //     return response.data.reverse();
  //   })
  //   .catch(function (error) {
  //     console.log(error);
  //   });
};

export const downloadFile = async (fileCode, filename, fileExtension = '') => {
  var axios = require('axios');
  var data = { codigoArquivo: fileCode };
  const { token } = getAuth();

  var config = {
    method: 'post',
    url: `${CONFIG.API_URL}/arquivos/download`,
    headers: {
      Bearer: token
    },
    data: data
  };

  return axios(config)
    .then(function (response) {
      const decoded = Base64.atob(response.data);

      return require('downloadjs')(
        decoded,
        filename,
        `application/${fileExtension}`
      );
    })
    .catch(function (error) {
      Vue.$toast.error('Erro ao efetuar o download.', {
        position: 'top-right',
        duration: 5000
      });
      console.log(error);
    });
};

export const applyFormat = (fKey, item) => {
  const fmtObj = fKey.format;

  // caso seja uma coluna calculada, não aplicar nova formatação
  if (fKey.parentKey) {
    return item[fKey.key];
  }

  if (fKey && fmtObj) {
    const fmtPattern = fKey.format.pattern;

    if (fmtObj.type === 'date' && fmtPattern) {
      return dayjs(item[fKey.key]).format(fmtPattern);
    }
  }

  return item[fKey.key];
};

export const buildFileList = (response, columnList) => {
  const fileList = response.map((resp) => {
    columnList.forEach((columnItem) => {
      if (columnItem.parentKey) {
        expandResp(resp, columnItem);
      }
    });
    return resp;
  });

  return fileList;
};

const expandResp = (resp, columnItem) => {
  resp[columnItem.key] = applyFormatOnParent(columnItem, resp);
};

export const extractParentItem = (parentKey, columnList) => {
  let parentItemList = columnList.filter((item) => item.key == parentKey);
  if (parentItemList && parentItemList.length == 0) {
    return parentItemList[0];
  }
  return null;
};

export const applyFormatOnParent = (fKey, item) => {
  const fmtObj = fKey.format;

  if (fKey && fmtObj) {
    const fmtPattern = fKey.format.pattern;

    if (fmtObj.type === 'date' && fmtPattern) {
      return dayjs(item[fKey.parentKey]).format(fmtPattern);
    }
  }

  return item[fKey.parentKey];
};

export const hasKey = (key, filterList) => {
  let filters = filterList;
  let index = -1;
  if (typeof filters !== 'undefined') {
    index = filters.findIndex((fil) => fil.key === key);
  }
  return index !== -1;
};
